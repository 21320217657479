<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true">
				<el-form-item>
					<div class="text-red"><i class="el-icon-warning margin-right-sm"></i>非开发人员请勿操作该页面</div>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" row-key="id" v-loading="loading" default-expand-all>
			<el-table-column prop="title" label="名称"></el-table-column>
			<el-table-column prop="id" label="ID" width="300"></el-table-column>
			<el-table-column fixed="right" label="操作" width="180">
				<template slot-scope="scope">
					<el-button size="mini" @click="toEdit(scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(power) {
				if (power) {
					// 编辑
					this.$router.push(`/systemPower/edit/${power.id}`);
				} else {
					// 新增
					this.$router.push("/systemPower/edit");
				}
			},
			loadData() {
				this.loading = true;
				this.$api.system.getPowers().then(res => {
					this.tableData = res.data;
					this.loading = false;
				})
			},
			del(user) {
				this.$confirm("此操作将永久删除该用户, 是否继续?", {
						type: "warning"
					})
					.then(() => {
						console.log(user);
						// const loading = this.$loading();
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		}
	};
</script>
<style scoped>
	.el-table>>>.el-table__fixed-right::before {
		height: 0;
	}

	.el-table::before {
		height: 0;
	}
</style>
